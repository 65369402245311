<template>
  <div>
    <div class="container my-5 gap-100">
      <div class="row">
        <div
          class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-md-3"
        >
          <div class="card shadow">
            <div class="card-body p-5">
              <h3 class="cl-primary f-bold text-center">
                Forgot <span class="cl-secondary"> Password </span>
              </h3>
              <form class="mt-4" @submit.prevent="postResetPassword">
                <div class="form-group">
                  <!-- <label for="email">Email address</label> -->
                  <input
                    type="email"
                    class="form-control b-radius px-4"
                    id="email"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    v-model="form.email"
                    required
                  />
                  <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with
                                    anyone else.</small> -->
                </div>
                <div class="d-flex justify-content-around mt-3" v-if="!loading">
                  <button type="submit" class="btn btn-primary px-5">
                    Reset Password
                  </button>
                </div>
                <div class="d-flex justify-content-around" v-if="loading">
                  <loader-component></loader-component>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import LoaderComponent from "../../components/Loader.vue";
export default {
  components: { LoaderComponent },
  name: "ForgotPassword",
  data() {
    return {
      loading: false,
      form: {
        email: "",
      },
    };
  },
  methods: {
    postResetPassword() {
      this.loading = true;
      const endpoint = "send/password-reset";
      this.$api.post(endpoint, this.form, (status, data, message) => {
        if (status === 200) {
          this.loading = false;
          Vue.swal(
            "Success",
            "Please check your email for reset password!",
            "success"
          ).then(() => {
            this.$router.push("/home");
            this.showInv = true;
          });
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },

    // login() {
    //   const endpoint = "login";
    //   this.$api.post(endpoint, this.form, (status, data, message) => {
    //     if (status === 200) {
    //       var user = data.data.user;
    //       Vue.swal(
    //         "Login Success",
    //         "Welcome " + user.name + "!",
    //         "success"
    //       ).then(() => {
    //         // window.location.reload();
    //         if (user.role == "customers") {
    //           this.$router.push("/customers");
    //         } else if (user.role == "admin") {
    //           this.$router.push("/admin/dashboard");
    //         } else if (user.role == "warehouse") {
    //           this.$router.push("/warehouse/dashboard");
    //         }
    //       });
    //     } else {
    //       console.log(message);
    //     }
    //   });
    // },
  },
};
</script>
